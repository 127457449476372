import { Link } from "gatsby"
import React, { useEffect, Fragment } from "react"
import { scrollToElement } from "../../../../../utility/utils"
import CTA from "../../../general/cta"

const DebitCardInsurance = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (
    <Fragment>
      <div className="kuda-inner--heading kuda-disclaimer--section">
        <div className="kuda-section--inner general-style">
          <div className="kuda-section--100 inner-spotlight--heading pad-bottom text-center">
            <Link
              to="/en-ng/legal/"
              className="color-black mb-3 mt-4 back-page"
            >
              <div className="align-self-center mr-2">
                <div className="f-14">Legal</div>
              </div>
              <span className="mr-2">
                <svg
                  width={8}
                  height={14}
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                    fill="#C7C7CC"
                  ></path>
                </svg>
              </span>
              <span className="align-self-center f-14 mr-2 text-left">
                Nigeria
              </span>
              <span className="mr-2">
                <svg
                  width={8}
                  height={14}
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                    fill="#C7C7CC"
                  ></path>
                </svg>
              </span>
              <span className="align-self-center f-14 text-bold text-left">
                Terms & Conditions
              </span>
            </Link>
            <div className="legal-left">
              <h1 className="kuda-section--heading mb-0 text-lg-biz text-xlbold color-primary title-bottom--spacing no-margins">
                Terms & Conditions - Debit Card
              </h1>
            </div>
          </div>

          <div className="kuda-section--100 kuda-spacing--inner">
            <p className="color-black bottom--spacing">
              The following Terms and Conditions (“T & Cs”) apply to any person
              who applies for, accepts and / or uses a Debit Card issued by Kuda
              Microfinance Bank Limited (hereinafter referred to as “Kuda” or
              the “Bank”). In these T & Cs, “we” “us” or “our” refer to Kuda and
              its successors, agents and assigns, while “you” or “your” refers
              to a Cardholder.
            </p>

            <p className="color-black bottom--spacing">
              Please note that these T & Cs are in addition to the regular terms
              and conditions that regulate the usage of Kuda bank accounts as
              well as all other policies of the Bank. The Bank also reserves the
              right to determine the kind of Debit Card to issue to a customer
              depending on the customer’s account profile and other internal
              considerations.
            </p>

            <p className="color-black bottom--spacing">
              You will be considered to have given your consent to us for the
              processing of your personal data when you accept these T & Cs as
              indicated below. If you do not agree with these T & Cs, do not
              click on accept below or use the Debit Card. These T & Cs apply to
              both physical and virtual Cards issued by the Bank.
            </p>
          </div>

          <div className="kuda-section--100 kuda-spacing--inner">
            <h4 className="card-main--heading color-primary text-xbold mb-3">
              1. DEFINITIONS
            </h4>
            <div className="text-left kuda-disclaimer--wrap">
              <p className="color-black bottom--spacing">
                <span className="text-bold">Account - </span> means a bank
                account held or to be held with Kuda in the name of the
                Cardholder / Account Holder.
              </p>

              <p className="color-black bottom--spacing">
                <span className="text-bold">Account Holder - </span> means an
                individual, sole proprietorship, partnership or company
                operating an Account with Kuda.
              </p>

              <p className="color-black bottom--spacing">
                <span className="text-bold">ATM - </span> means Automated Teller
                Machine.
              </p>

              <p className="color-black bottom--spacing">
                <span className="text-bold">Card / Debit Card - </span> means a
                Kuda debit card issued to the Account Holder, and any other
                person specified by the Account Holder to Kuda, including any
                renewal or replacement cards issued to the Account Holder.
              </p>

              <p className="color-black bottom--spacing">
                <span className="text-bold">Cardholder - </span> means you or
                any person who applied for or uses a Kuda Card as well as the
                possessor of the card and/or persons having power alone to
                operate the Account in accordance with the Account’s mandate in
                respect thereof, having been so authorised by the Account
                Holder.
              </p>

              <p className="color-black bottom--spacing">
                <span className="text-bold">Card Expiration Date - </span>means
                the last day on which the Card will be valid as stated on the
                Debit Card
              </p>

              <p className="color-black bottom--spacing">
                <span className="text-bold">Merchant - </span> means a supplier
                of goods and services.
              </p>

              <p className="color-black bottom--spacing">
                <span className="text-bold">PIN - </span> means the Personal
                Identification Number issued to the Cardholder for use with the
                Card.
              </p>

              <p className="color-black bottom--spacing">
                <span className="text-bold">POS Terminal - </span> means the
                Point of Sale (POS) terminals at Merchant establishments in
                Nigeria, capable of processing card transactions and at which,
                amongst other things, the Cardholder can use his/her card to
                access the funds from the Account linked with the Card to make
                purchases.
              </p>

              <p className="color-black bottom--spacing">
                <span className="text-bold">Transaction - </span> means any cash
                withdrawal or payment made using the Card, or any refund arising
                in connection with the use of the Card in any authorised manner
                for debit or credit to the Account.
              </p>
            </div>
          </div>

          <div className="kuda-section--100 kuda-spacing--inner">
            <h4 className="card-main--heading color-primary text-xbold mb-3">
              2. USING YOUR KUDA DEBIT CARD
            </h4>
            <div className="text-left kuda-disclaimer--wrap">
              <p className="color-black bottom--spacing">
                Should you accept these T &Cs and / or use the Card:
              </p>

              <p className="color-black bottom--spacing">
                2.1 - We will issue you a Card that can be used at ATMs, POS
                Terminals, Merchant websites amongst others all based in Nigeria
                which accept the use of the applicable Card Scheme’s cards, to
                obtain cash and/or make payments for goods and services, as
                applicable. You may also use your Card for contactless
                transactions, subject to the transaction limit set by you on
                your Kuda App, which you may change anytime subject to
                applicable laws.
              </p>

              <p className="color-black bottom--spacing">
                2.2 - Upon setting up your Card, you shall comply with all
                instructions given to you regarding the activation, use and
                operation of the Card as stated in these T & Cs as well as other
                rules and guidelines provided by the Bank.
              </p>

              <p className="color-black bottom--spacing">
                2.3 - Your Card shall not be used for any unlawful transaction
                including the purchase of goods and/or services prohibited by
                the laws of the Federal Republic of Nigeria. Kuda may refuse to
                authorise the use of the Card if the transaction does not comply
                with any applicable terms of this T & Cs as may be amended from
                time to time. Please note that all transactions shall be
                detailed in the monthly account statements.
              </p>

              <p className="color-black bottom--spacing">
                2.4 - You agree not to attempt to withdraw cash using the Card
                unless sufficient funds are available in your Account. The onus
                of ensuring you have sufficient funds rests on you.
              </p>

              <p className="color-black bottom--spacing">
                2.5 - You shall take all reasonable and necessary precautions to
                prevent the unauthorised use of the Card and you accept
                liability for any loss incurred or any other adverse situation
                arising from your failure to do so or your non-compliance with
                any of these terms in these T & Cs.
              </p>

              <p className="color-black bottom--spacing">
                2.6 - In instances where the Account to which the Card is linked
                is jointly owned or operated, all your responsibilities as well
                as liabilities with respect to that Account and the Card shall
                be joint and several.
              </p>

              <p className="color-black bottom--spacing">
                2.7 - In using your Card, you may need to access service points,
                websites or portals owned and controlled by third parties or
                requiring action or service(s) provided by third parties. You
                agree to be bound by the terms and conditions of such third
                party service providers or access and that the Bank is
                exonerated from any liability, loss or claim arising from such
                usage or transactions which require actions outside our
                exclusive control.
              </p>
            </div>
          </div>

          <div className="kuda-section--100 kuda-spacing--inner">
            <h4 className="card-main--heading color-primary text-xbold mb-3">
              3. PERSONAL IDENTIFICATION NUMBER (PIN)
            </h4>
            <div className="text-left kuda-disclaimer--wrap">
              <p className="color-black bottom--spacing">
                3.1 - You agree that upon receipt of your card, you shall
                activate your Card for use by setting your PIN which shall be
                used by you to authorise Transactions using your Card. You shall
                keep the Card and PIN secure at all times, and not disclose it
                to any other person(s). You agree that all Transactions made
                using your Card and with your PIN will be treated as having been
                authorised by you and in line with your account mandate. Kuda
                shall not accept any liability for any alleged unauthorised use
                of the Card. You acknowledge, represent, and warrant that the
                PIN was set up by you, and you accept to be responsible for its
                use, confidentiality, and protection, as well as for all orders
                and information changes entered into the Account using such PIN.
              </p>
              <p className="color-black bottom--spacing">
                You also grant express authority to Kuda for carrying out
                transactions and instructions authenticated by the PIN and shall
                not revoke the same except in accordance with these T & Cs.
              </p>
            </div>

            <div className="kuda-section--100 kuda-spacing--inner">
              <p className="color-black bottom--spacing">
                3.2 - <span className="text-bold">Securing your PIN</span>{" "}
                <br /> <br />
                Kuda has no further obligation to verify the authenticity of a
                Transaction instruction sent or purported to have been sent from
                the Cardholder other than by means of verification of the
                Cardholder’s PIN. You must, therefore, take all reasonable
                precautions to prevent your Card and PIN from being used
                fraudulently or you may be liable for any losses incurred by
                you. Reasonable precautions include but are not limited to:
              </p>

              <div className="ml-2">
                <p className="color-black mb-2">
                  a. Keeping your Card, PIN and other related details safe and
                  secure at all times.
                </p>

                <p className="color-black mb-2">
                  b. Not interfering with any magnetic stripe or chip in the
                  Card;
                </p>

                <p className="color-black mb-2">
                  c. Not disclosing the card number except when properly using
                  the Card;
                </p>

                <p className="color-black mb-2">
                  d. Destroying any notification of your PIN;
                </p>

                <p className="color-black mb-2">
                  e. Not writing down or recording in any format your PIN or
                  disclosing it to anyone else (other than any additional
                  Cardholder for businesses and companies), including the police
                  or bank officials and in response to any emails, SMS messages
                  or calls;
                </p>

                <p className="color-black mb-2">
                  f. Not using PINs that can easily be guessed by third parties;
                </p>

                <p className="color-black mb-2">
                  g. Notifying us immediately your Card is lost or stolen;
                </p>

                <p className="color-black mb-2">
                  h. Complying with any other reasonable instruction we may
                  advise regularly on keeping your Card details and PIN safe.
                </p>
              </div>
            </div>
          </div>

          <div className="kuda-section--100 kuda-spacing--inner">
            <h4 className="card-main--heading color-primary text-xbold mb-3">
              4. OUR RIGHTS
            </h4>
            <div className="kuda-section--100 kuda-spacing--inner">
              <p className="color-black bottom--spacing">
                4.1 -{" "}
                <span className="text-bold">
                  Authority to charge your Account
                </span>{" "}
                - Any Transaction you make using your Card will be charged to /
                debited from your Account. The Transaction is your irrevocable
                authority to us to debit the requisite sum plus any applicable
                charges from your Account and pay same to the appropriate party
                (i.e. other banks, POS Terminal owners or Merchants).
              </p>

              <p className="color-black bottom--spacing">
                4.2 - <span className="text-bold">Card Limits</span> - We
                reserve the right to establish and change limits concerning your
                use of the Card or related services including but not limited to
                Transaction limits and thresholds, in accordance with applicable
                laws and regulations. You may be able to customise your card
                limits within the set limits and thresholds.
              </p>

              <p className="color-black bottom--spacing">
                4.3 - We may refuse to authorise your use of the Card if: (a)
                the Transaction exceeds the available funds in your Account; (b)
                the Transaction does not comply with these T & Cs, any
                applicable laws and regulations or is in furtherance of any
                unlawful purposes; (c) we reasonably determine that a refusal is
                prudent or practical under the particular circumstance.
              </p>

              <p className="color-black bottom--spacing">
                4.4 - We may take any precautionary measure(s) deemed necessary
                and appropriate for the protection of your Card and Account if
                there are any reasons to suspect an unauthorised, fraudulent or
                non-compliant Transaction. If any establishment requests for
                authorisation from us before accepting payment by the Card, we
                may decline if: (a) the Card has been reported as missing or
                compromised in any way; (b) You are in breach of these T &Cs
              </p>

              <p className="color-black bottom--spacing">
                4.5 - Notwithstanding anything contained herein, we further
                reserve the right to exercise our discretion to limit, suspend
                or cancel your right or ability to use the Card. We may do this
                through any one or more of the following: (a) suspending or
                cancelling your right to use the Card, (b) refusing to approve a
                Transaction; (c) refusing to replace / renew your Card, (d) any
                other manner deemed appropriate and necessary by the Bank. These
                T & Cs shall be deemed to continue to subsist irrespective of
                the happening of any events stated in this clause.
              </p>

              <p className="color-black bottom--spacing">
                4.6 - Card Requests - To prevent the potential use of our Cards
                for suspicious, fraudulent or other unlawful activities, each
                Customer will only be able to apply for and activate:
              </p>

              <p className="color-black title-bottom--spacing">
                4.6.1 - One Virtual Card at any given time;
              </p>

              <p className="color-black bottom--spacing">
                4.6.2 - Up to a certain number of cards (Physical or Virtual),
                irrespective of whether these are new applications or
                replacement applications, during a specific time period, as
                determined by the Bank from time to time, at its discretion.
              </p>
            </div>
          </div>

          <div className="kuda-section--100 kuda-spacing--inner">
            <h4 className="card-main--heading color-primary text-xbold mb-3">
              5. FEES AND CHARGES
            </h4>
            <div className="kuda-section--100 kuda-spacing--inner">
              <p className="color-black bottom--spacing">
                5.1 - <span className="text-bold">Card Issuance Fee: </span>
                Cardholders shall be charged a fee for card issuance and this
                will be deducted from your Account when you request a Card.
              </p>

              <p className="color-black bottom--spacing">
                5.2 - <span className="text-bold">Delivery Fee: </span>
                In addition to this, where applicable, we will charge you a sum
                for delivering the Card to you personally at the address you
                have provided. You will also be charged an additional fee for
                every re-attempted delivery, where you are unavailable to
                receive your Card through no fault of ours.
              </p>

              <p className="color-black bottom--spacing">
                5.3 - <span className="text-bold">Other Charges: </span>
                Charges which may be applicable to the Card include but may not
                be limited to: charges for Transactions carried out using the
                Card, charges for card replacement, renewal. These charges when
                applicable will be debited from your Account without further
                recourse to you. These charges could also be statutory in nature
                and may include charges imposed by other banks and financial
                institutions for the use of your Card on their payment platforms
                (such as ATMs).
              </p>

              <p className="color-black bottom--spacing">
                5.4 - <span className="text-bold">Transaction Fees: </span>
                Kuda reserves the right to charge transaction fees on the use of
                your Card for cash withdrawals or bill payments completed at
                ATMs or POS Terminals and payments completed on Merchant
                websites in accordance with applicable laws / card scheme rules,
                where applicable. We accept no responsibility for any surcharge
                levied by any Merchant and debited to the Account linked with
                your Card with the Transaction amount. Any charge or other
                payment requisition received from a Merchant by us for payment
                shall be conclusive proof that the charge recorded on such
                requisition was properly incurred at the Merchant’s
                establishment or Website for the amount and by the Cardholder
                using the Card referred to in that charge or other requisition,
                except where the Card has been lost, stolen or fraudulently
                misused, the burden of proof for which shall be on the
                Cardholder.
              </p>

              <p className="color-black bottom--spacing">
                5.5 - The above charges are subject to review at any time and we
                shall notify You of any such reviews or changes. You hereby
                exonerate the Bank from any liability for deducting such charges
                from Your Account where You continue to use the Card for
                Transactions after such notification
              </p>
            </div>
          </div>

          <div className="kuda-section--100 kuda-spacing--inner">
            <h4 className="card-main--heading color-primary text-xbold mb-3">
              6. LIMIT ON YOUR RIGHT TO USE THE CARD
            </h4>
            <div className="kuda-section--100 kuda-spacing--inner">
              <p className="color-black bottom--spacing">
                6.1 - You agree that the Card is and shall remain the property
                of Kuda and may be withdrawn from you on demand. We may also
                withdraw your Card, if it is used for unlawful / fraudulent
                transactions
              </p>

              <p className="color-black title-bottom--spacing">
                6.2 - Withdrawals from ATMs with your Card and PIN shall not
                exceed a maximum limit as may be specified by us and in
                accordance with applicable regulations from time-to-time with /
                without prior notice to you. We shall not be liable:
              </p>

              <div className="color-black bottom--spacing ml-3 f-16">
                <ul className="diclaimer-list">
                  <li className="mb-2">
                    for any events that may occur after an ATM has successfully
                    dispensed cash for withdrawal transactions or a successful
                    acknowledgment of a POS transaction;
                  </li>

                  <li className="mb-2">
                    if a circumstance warrants the card to be trapped by an ATM;
                  </li>

                  <li className="mb-2">
                    for any machine malfunction, strike, disputes or any other
                    circumstances affecting the use of your Card.
                  </li>
                </ul>
              </div>

              <p className="color-black bottom--spacing">
                6.3 - <span className="text-bold">Expiration </span> - Your Card
                shall expire on the Card Expiration Date stated on it and the
                re-issuance of another is at our discretion. The Card must not
                be used after the Card Expiration Date. You would have to
                initiate a request for the reissuance.
              </p>
              <p className="color-black bottom--spacing">
                6.4 - <span className="text-bold">Compromised Cards </span> - In
                certain circumstances we may discover that your Card and the
                details relating to your Card have been compromised and will do
                our best to notify you as soon as we discover this and will
                place a temporary restriction on your Card to protect the funds
                in your Kuda Account. When we notify you of a compromised Card,
                you will be required to give us your consent to deactivate the
                Card as there are inherent risks to your Kuda Account if you
                continue to use a compromised Card. Where, however, you elect to
                continue to use the compromised Card and direct us to lift the
                temporary restriction, you will be required to provide us with
                an indemnity in respect of any potential losses or claims you or
                the Bank may suffer as a result of your decision.
              </p>
              <p className="color-black bottom--spacing">
                6.5 - In relation to a Cardholder’s use of the Cards we issue,
                we reserve the right to take any lawful steps necessary to
                protect the integrity of our systems and customer funds,
                including but not limited to placing temporary restrictions on
                your Card pending the outcome of any investigations or extended
                due diligence exercises relating to your Kuda Account, Card or
                any transaction.
              </p>
            </div>
          </div>

          <div className="kuda-section--100 kuda-spacing--inner">
            <h4 className="card-main--heading color-primary text-xbold mb-3">
              7. DISPUTED TRANSACTIONS / CHARGEBACKS
            </h4>
            <div className="kuda-section--100 kuda-spacing--inner">
              <p className="color-black bottom--spacing">
                Chargeback situations may occur due to disputes, unauthorised
                transactions, non-compliance with Card Scheme Rules, or other
                valid reasons. In such cases, we aim to provide a seamless and
                efficient chargeback process to address any concerns promptly.
                Disputed Transactions / Chargeback claims must be reported to
                the Bank by a Cardholder as soon as possible to enable us to
                consider and investigate the claim and initiate a chargeback
                process where we deem that the claim is valid and justified. You
                will be requested to provide us with full details and where
                necessary documentation relating to any Transaction which you
                have disputed or reported in this regard. Working with the
                relevant Card Scheme and other stakeholders, we aim to process
                chargebacks within the stipulated time as prescribed from time
                to time by the Relevant regulatory authority, where a valid
                claim is established. We will credit your Account with a refund
                for any successful chargeback claim.
              </p>
            </div>
          </div>

          <div className="kuda-section--100 kuda-spacing--inner">
            <h4 className="card-main--heading color-primary text-xbold mb-3">
              8. FAILED CARD TRANSACTIONS - INSUFFICIENT FUNDS
            </h4>
            <div className="kuda-section--100 kuda-spacing--inner">
              <p className="color-black bottom--spacing">
                8.1 - Failed Card Transactions in this context, refer to
                Transactions that are not completed as a result of insufficient
                Funds in your Kuda Account. This can occur in respect of web
                payments, ATM transactions as well as POS Terminal transactions.
              </p>

              <p className="color-black bottom--spacing">
                8.2 -{" "}
                <span className="text-bold">
                  Web and Certain Platform Usage
                </span>{" "}
                - You may add your Kuda card as a payment option on certain
                merchant websites and platforms
                <span className="text-bold"> (“Subscription Billers”)</span> for
                regular, fixed or periodic payments (usually in the form of
                subscriptions or payments for goods or services){" "}
                <span className="text-bold">(“Recurring Payment(s)”).</span>
                This authorises these merchant’s to automatically debit the
                agreed sum from your Kuda Account on set-dates subject to your
                agreed terms and conditions with them. Any fees that are
                incidental to this process, will be borne by you and will be
                charged to your Kuda Account.
              </p>

              <p className="color-black bottom--spacing">
                8.2.1 - Where your Kuda Account is not sufficiently funded to
                enable us honour a Recurring Payment this payment will not be
                completed. Most times, the Subscription Billers will continue to
                attempt to debit your Kuda Account using your Kuda Card for this
                payment, thereby generating several failed transactions in the
                process.
              </p>

              <p className="color-black bottom--spacing">
                8.3 -{" "}
                <span className="text-bold">ATM and POS Terminal Usage</span> -
                Failed Card Transactions also occur when you attempt to withdraw
                funds from or use your Kuda Card at ATMs and POS Terminals when
                there is insufficient funds in your Kuda Account.
              </p>

              <p className="color-black bottom--spacing">
                8.4 - <span className="text-bold">What this means for you</span>{" "}
                - Service providers charge us a fee for authorizations relating
                to each Failed Card Transaction and we may have to pass on these
                costs to you. We may also be constrained to disable / block your
                card where there are continued cases of Failed Card Transactions
                despite our notices to You. To prevent this, you should always
                ensure that your Kuda Account is sufficiently funded before any
                date on which a Recurring Payment is due to be debited from your
                Kuda Account or before you attempt to use your Card at payment
                terminals (ATMs or POS). If you do not wish to continue using
                the services for which Recurring Payments are debited from your
                Kuda Account, you are advised to remove / delist your Card from
                the website or platform of the Subscription Biller(s).
              </p>
            </div>
          </div>

          <div className="kuda-section--100 kuda-spacing--inner">
            <h4 className="card-main--heading color-primary text-xbold mb-3">
              9. WHEN YOU SHOULD NOTIFY US - STOLEN / MISSING CARD
            </h4>
            <div className="kuda-section--100 kuda-spacing--inner">
              <p className="color-black bottom--spacing">
                9.1 - You should notify us in any of the following instances as
                soon as possible:
              </p>

              <div className="ml-3">
                <p className="color-black mb-2">
                  (a) Immediately your Card is lost or stolen, or you believe
                  your Card may be compromised or if the PIN is disclosed to any
                  unauthorised persons or suspected to have been compromised.
                  Also, endeavour to block / disable the Card on your Kuda App.
                  We will stop the use of your Card and “hotlist” it as soon as
                  reasonably possible after being advised to do so. You must
                  also report any loss or theft of your Card to the Nigerian
                  Police Force and file the relevant report.
                </p>

                <p className="color-black mb-2">
                  (b) If your account statement includes a Transaction,
                  particulars or details which you think are wrong or
                  inaccurate.
                </p>

                <p className="color-black mb-2">
                  (c) Immediately you change your name, phone number or address.
                </p>
              </div>
            </div>
          </div>

          <div className="kuda-section--100 kuda-spacing--inner">
            <h4 className="card-main--heading color-primary text-xbold mb-3">
              10. LIMITATION OF LIABILITY
            </h4>
            <div className="kuda-section--100 kuda-spacing--inner">
              <p className="color-black bottom--spacing">
                10.1 - We do not warrant that the services that we provide
                pursuant to this T & Cs will always be available. We reserve the
                right to withdraw or vary these services at any time. We will
                not be liable to You or anyone if we are unable to carry out our
                responsibilities under these T & Cs as a result of the fault of
                a third party or anything that we cannot reasonably control or
                that does not fall within our purview. These include but shall
                not be limited to: network downtime or failures, system
                downtimes or maintenance issues, delays or malfunctions or any
                other related event arising from an external interface; the
                actions or omissions of any telecommunications authority,
                processor, failure of ATM/POS Terminal to work or our supporting
                or shared networks, strike, industrial action/disputes, natural
                disasters, acts of God etc.
              </p>

              <p className="color-black bottom--spacing">
                10.2 - Kuda disclaims any liability for any Transaction related
                to or arising from fraud or such other illegality. Except when
                caused by the Bank’s proven misconduct or negligence, we shall
                not be liable or responsible for any loss, injury, or damage
                whether direct or indirect, special or consequential caused by
                or arising from the use of the Card including any loss or
                financial fraud arising from loss, unauthorised initiation of
                Transactions. You shall indemnify and hold us harmless from and
                against any losses, damages, fines or related claims related to
                this. Furthermore, You undertake to indemnify and hold us
                harmless against any claims or losses arising from the unlawful
                use of your Card on any platform or in any manner whatsoever.
              </p>

              <p className="color-black bottom--spacing">
                10.3 - If the Bank provides any information to you via email,
                telephone or other channels, the details of which You provided
                to the Bank, the Bank shall not be liable if the information is
                intercepted, altered or misused by any unauthorised person.
              </p>

              <p className="color-black bottom--spacing">
                10.4 - Until you notify us that your Card is lost, stolen or at
                risk of being misused or block / disable the Card on yourKuda
                App, you will be liable for Transactions carried out through it,
                before we acknowledge receipt of the notification.
              </p>
            </div>
          </div>

          <div className="kuda-section--100 kuda-spacing--inner">
            <h4 className="card-main--heading color-primary text-xbold mb-3">
              11. DEACTIVATING YOUR CARD
            </h4>
            <div className="kuda-section--100 kuda-spacing--inner">
              <p className="color-black bottom--spacing">
                11.1 - You have the right to deactivate your Card and terminate
                these T &Cs by writing to us using the email stated below. You
                may also deactivate your Card, yourself, on your Kuda App by
                blocking the Card and thereafter accepting the cancel and delete
                prompt. A Card not deactivated correctly may still be used.
                Should this happen, you will be responsible for any Transactions
                the Card is used. The deactivation of your Card will not operate
                to discharge you of any liability or sums owed to us prior to
                the deactivation.
              </p>

              <p className="color-black bottom--spacing">
                11.2 - Without prejudice to any other provision in these T & Cs,
                the Bank may terminate these T & Cs and / or withdraw the
                services provided hereunder upon giving notice to You. We
                reserve the right to terminate this Agreement at any time and
                your access to our Cards if we suspect or establish that you
                have used your Cards for suspicious, fraudulent or unlawful
                activity contrary to these T & Cs or applicable laws.
              </p>
            </div>
          </div>

          <div className="kuda-section--100 kuda-spacing--inner">
            <h4 className="card-main--heading color-primary text-xbold mb-3">
              12. GENERAL TERMS
            </h4>
            <div className="kuda-section--100 kuda-spacing--inner">
              <p className="color-black bottom--spacing">
                12.1 - We may, at any time, modify, revise and make changes to
                the provisions of these T&Cs and we will notify you of such
                changes after which they will become effective. All updates will
                be detailed on our website and the Kuda App. You will be able to
                access the latest version of our terms at any given time.
                Without prejudice to the other provisions of these T and Cs and
                any of our other rights, if you do not wish to accept any
                amendments we make to these T and Cs in the future, you will be
                able to opt out of these T and Cs and deactivate your Card
                without any penalty. Non-acceptance of updates to these T and Cs
                also gives us a right to deactivate your Card.
              </p>

              <p className="color-black bottom--spacing">
                12.2 - No delay in the enforcement of any clause contained in
                these T & Cs shall be deemed to be a waiver of the Bank’s rights
                under these Terms, nor shall the Bank be prevented from
                enforcing its rights under these T and Cs at a later date. For
                the avoidance of doubt, any delay by the Bank for any reason
                whatsoever in debiting a customer’s account for any fees or
                lawful charges incurred by the customer as a result of either an
                application for a Debit Card or the customer’s use of the Debit
                Card shall not constitute a waiver of the Bank’s right to deduct
                that fee at any time from the Customer’s account even after the
                service in question has been provided by the Bank. The Customer
                hereby consents to the Bank’s ability to make such lawful
                deductions from their bank account.
              </p>

              <p className="color-black bottom--spacing">
                12.3 - Your rights under these T & Cs are personal to You and
                cannot be assigned or transferred to a third party.{" "}
              </p>

              <p className="color-black bottom--spacing">
                12.4 - You hereby consent to the Bank’s collection, storage and
                processing of your personal data provided by You in connection
                with the services provided under these T&Cs.
              </p>

              <p className="color-black bottom--spacing">
                12.5 - You authorise us to exercise a right of set-off at any
                time we deem fit in respect of your Account or any other account
                you maintain with us to settle any of your outstanding payments
                to us.{" "}
              </p>

              <p className="color-black bottom--spacing">
                12.6 - <span className="text-bold">Notice</span> <br /> <br />
                Please contact us if you need further explanation on anything
                related to the use of your card. You may contact us at{" "}
                <a
                  href="mailto:help@kuda.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="color-primary link-decoration cursor-pointer ml-1"
                >
                  help@kuda.com
                </a>{" "}
                or speak to our customer care representatives on 0700022555832.
                We may notify you about any changes or information by: (a)
                Sending you in-App messages or emails, (b) advertising in the
                press, our website any other means of public notification; (c)
                including messages in your account statements (d) any other
                means of communication that we deem appropriate.
              </p>

              <p className="color-black bottom--spacing">
                12.7 - These Terms and Conditions shall be governed by the laws
                of the Federal Republic of Nigeria.
              </p>
            </div>
          </div>
        </div>
      </div>
      <CTA />
    </Fragment>
  )
}

export default DebitCardInsurance
